import React, { useContext } from 'react';
import { LanguageContext } from 'context';
import { translateData } from 'utils';
import EnLogo from './enLogo';
import pattern from '../../assets/images/footer-pattern.svg';
import { FooterBottomPanel } from 'components';
import samf from '../../assets/images/samf-logo-min.svg';
import { SocialIconsPanel } from 'components';

const Footer = () => {
  const data = useContext(LanguageContext);
  const currentLang = data.currentLang;
  const linksInfo = translateData(data.staticData);

  return (
    <section className='footer-area'>
      <div className='container'>
        <div className='top-panel'>
          <div className='footer-content'>
            <div className='logos-wrapper'>
              <div>
                <EnLogo />
              </div>
              <div>
                <a className='d-block' href='http://samf.gov.sa/' target='_blank'>
                  <img className='samf-logo' src={samf} alt='samf' />
                </a>
              </div>
            </div>

            <div>
              <h1>{linksInfo['link-contact-us']}</h1>
              <h4 className='company-name'>
                {currentLang === 'en-US' ? `Saudi Motorsport Company` : `شركة رياضة المحركات السعودية`}
              </h4>
              <a
                href='mailto:contact-us@saudimotorsport.com'
                className='company-email'
              >
                {linksInfo['email']}: contact-us@saudimotorsport.com
              </a>
            </div>

            <div>
              <h1>{linksInfo['follow-us'] || 'Follow Us'}</h1>
              <div className='social-icons-wrapper'>
                <SocialIconsPanel />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterBottomPanel />
    </section>
  );
};

export default Footer;
