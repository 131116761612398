import React from 'react';
import {
  TopBanner,
  NotFoundComponent,
  MailChimpForm,
  Button,
  EventsGrid,
  LatestNews,
  OngoingEvents,
  OurVision,
  TopVideoBanner,
  AboutUs,
  EventsCarousel,
  SponsorCarousel,
  VehicleCategories,
  Gallery,
  AboutEvent,
  DriversTabsCarousel,
  OpeningClosingCeremonies,
  TheRoute,
  GetZone,
  CornicheCircuit,
  RaceFacts,
  TeamStandings,
  DriversCarousel,
  ContentBlock,
  AllNews,
  Video,
  AboutCircuit,
  VenueMap,
  EventsCalendar,
  AboutKarting,
  KartingIntroduction,
  DesertXpCarousel,
  TeamCarousel,
  WebRowLayoutWithAboutEvent,
  JccFitRegisterForm,
  Magazine,
  FerrariEventSchedule,
  CustomHtml,
} from 'components';
import HomeHero from '../homeHero';
import ImagesGrid from '../imagesGrid/imagesGrid';
import UpcomingEvents from '../upcomingEvents/upcomingEvents';
import SaudiExperience from '../saudiExperience/saudiExperience';
import DakarRoute from '../dakarRoute';

const Component = ({ component = {}, wrapper = '' }) => {
  const { type } = component;
  switch (type) {
    case 'ferrari-schedule':
      return <FerrariEventSchedule component={component} wrapper={wrapper} />;
    case 'home-hero':
      return <HomeHero component={component} wrapper={wrapper} />;
    case 'top-banner':
      return <TopBanner component={component} wrapper={wrapper} />;
    case 'not-found':
      return <NotFoundComponent component={component} />;
    case 'mailchimp-form':
      return <MailChimpForm component={component} />;
    case 'button':
      return <Button btn={component} className='btn-primary' />;
    case 'events-grid':
      return <EventsGrid component={component} wrapper={wrapper} />;
    case 'latest-news':
      return <LatestNews component={component} />;
    case 'ongoing-events':
      return <OngoingEvents component={component} />;
    case 'our-vision':
      return <OurVision component={component} />;
    case 'top-video-banner':
      return <TopVideoBanner component={component} />;
    case 'about-us':
      return <AboutUs component={component} />;
    case 'events-carousel':
      return <UpcomingEvents component={component} />;
    case 'sponsor-carousel':
      return <SponsorCarousel component={component} wrapper={wrapper} />;
    case 'vehicle-categories':
      return <VehicleCategories component={component} />;
    case 'image-gallery':
      return <Gallery component={component} />;
    case 'about-event':
      return <AboutEvent component={component} wrapper={wrapper} />;
    case 'drivers-tabs-carousel':
      return <DriversTabsCarousel component={component} />;
    case 'opening-closing-ceremonies':
      return <OpeningClosingCeremonies component={component} />;
    case 'the-route':
      return <TheRoute component={component} />;
    case 'get-zone':
      return <GetZone component={component} />;
    case 'corniche-circuit':
      return <CornicheCircuit component={component} />;
    case 'race-facts':
      return <RaceFacts component={component} />;
    case 'team-standings':
      return <TeamStandings component={component} />;
    case 'drivers-carousel':
      return <DriversCarousel component={component} wrapper={wrapper} />;
    case 'content-block':
      return <ContentBlock component={component} wrapper={wrapper} />;
    case 'all-news-page':
      return <AllNews component={component} wrapper={wrapper} />;
    case 'video-component':
      return <Video component={component} wrapper={wrapper} />;
    case 'about-circuit':
      return <AboutCircuit component={component} />;
    case 'venue-map':
      return <VenueMap component={component} />;
    case 'events-calendar':
      return <EventsCalendar component={component} />;
    case 'about-karting':
      return <AboutKarting component={component} />;
    case 'karting-introduction':
      return <KartingIntroduction component={component} />;
    case 'desert-xp-carousel':
      return <DesertXpCarousel component={component} />;
    case 'team-carousel':
      return <TeamCarousel component={component} />;
    case 'about-events-list':
      return <WebRowLayoutWithAboutEvent component={component} />;
    case 'jcc-fit-register-form':
      return <JccFitRegisterForm component={component} />;
    case 'magazine':
      return <Magazine component={component} />;
    case 'custom-html':
      return <CustomHtml component={component} />;
    case 'images-grid':
      return <ImagesGrid component={component} />;
    case 'saudi-experience':
      return <SaudiExperience component={component} />;
    case 'dakar-route':
      return <DakarRoute component={component} />;
    default:
      return <></>;
  }
};

export default Component;
