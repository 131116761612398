import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const SponsorCarousel = ({ component, wrapper = '' }) => {
  const sponsors = component.sponsorsItems;

  return (
    <section className='sponsors-area'>
      <div className='sponsors-container'>
        <div className='sponsors-list'>
          {sponsors.map((item) => {
            const { id, logoImage, link } = item;
            const image = getImage(logoImage);
            return (
              <a href={link} target='_blank' key={id} className='sponsor-box'>
                <GatsbyImage
                  image={image}
                  alt={logoImage.description}
                  className='sponsor-image'
                  style={logoImage.description === 'Protomin' ? { width: '250px' } : {}}
                />
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SponsorCarousel;
