import React, { useContext } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby'
import { LanguageContext } from 'context'

const EventItem = ({ event }) => {
  const img = getImage(event.eventMainImage);
  const currentLang = useContext(LanguageContext).currentLang

  return (
    <div key={event.id} className='event'>
      <div className='event-img' style={{ flexShrink: 0 }}>
        <GatsbyImage image={img} alt={event.eventMainImage?.description} />
      </div>

      <div className='event-info'>
        <div>
          <h3 className='event-name'>
            <Link to={currentLang === 'en-US' ? `/en/${event.slug}` : `/${event.slug}`}>{event.eventName}</Link>{' '}
          </h3>

          <p className='event-description'>{event.eventDescription.eventDescription}</p>

          {event.startDate && (
            <div className='event-date'>
              <span>
                {new Date(event.startDate).toLocaleString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
              </span>
              -
              <span>
                {new Date(event.endDate).toLocaleString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}
              </span>
            </div>
          )}
        </div>

        {/* {event.buyTicketsButton && (
          <a
            href={event.buyTicketsButton?.link}
            className='primary-btn'
            style={{
              flexShrink: 0,
              color: "white"
            }}
          >
            {event.buyTicketsButton?.label}
          </a>
        )} */}
      </div>
    </div>
  );
};

export default EventItem;
