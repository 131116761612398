import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const VehicleCategories = ({ component }) => {
  const { title, categories } = component;

  return (
    <section className='vehicle-categories'>
      <div className='container'>
        <div className='sec-title'>
          <i className='pattern'></i>
          <h1>{title}</h1>
        </div>
        <div className='row'>
          {categories.map((category) => {
            const { id, vehicleName, image } = category;
            const imageBg = getImage(image);
            const bgImage = convertToBgImage(imageBg);
            return (
              <div className='col-md-4' key={id}>
                <BackgroundImage key={id} className='category-box' Tag='div' {...bgImage} preserveStackingContext>
                  <h4 className='vehicle-name'>{vehicleName}</h4>
                </BackgroundImage>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default VehicleCategories;
