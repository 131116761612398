import React, { useEffect } from 'react';

const CustomHtml = ({ component }) => {
  const { html, title, description } = component;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://seekbeak.com/js/sbembed.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section className='virtual-map' style={{ marginBottom: '100px' }}>
      <div className='sec-title container' style={{ marginBottom: '50px' }}>
        <i className='pattern'></i>
        <h1>{title}</h1>
        {description && (
          <p className='virtual-map-description'>{description.description}</p>
        )}
      </div>
      <div dangerouslySetInnerHTML={{ __html: html.html }} />
    </section>
  );
};

export default CustomHtml;
