import React, { useState, useRef, useLayoutEffect, useContext } from 'react';
import EventItem from './eventItem';
import { LanguageContext } from 'context';
import { Link } from 'gatsby';

const UpcomingEvents = ({ component }) => {
  const { title, events, button, mainTitle } = component;
  const sectionRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(null);
  const datesRef = useRef([]);
  const currentLang = useContext(LanguageContext).currentLang;

  const upcomingEvents = events.filter((event) => {
    const currentDate = new Date();
    const eventDate = new Date(event.endDate);

    return eventDate >= currentDate;
  });

  const dates = upcomingEvents
    .map((event) => event.startDate)
    .filter(Boolean)
    .sort();

  const currentSelectedDateIndex = dates.findIndex((date) => date === currentDate);

  const filteredEvents = currentDate
    ? upcomingEvents.filter((event) => event.startDate === currentDate)
    : upcomingEvents;

  const handlePrev = () => {
    if (currentSelectedDateIndex > 0) {
      setCurrentDate(dates[currentSelectedDateIndex - 1]);
    }
  };

  const handleNext = () => {
    if (currentSelectedDateIndex < dates.length - 1) {
      setCurrentDate(dates[currentSelectedDateIndex + 1]);
    }
  };

  useLayoutEffect(() => {
    datesRef.current[currentSelectedDateIndex]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  }, [currentSelectedDateIndex]);

  return (
    <section ref={sectionRef} className='upcoming-events'>
      <div className='container'>
        <div className='sec-title'>
          <div className='upcoming-events-subtitle'>{mainTitle}</div>
          {/* <h2 className='upcoming-events-title'>{title}</h2> */}
        </div>

        <div className='upcoming-events-calendar'>
          <button
            className='events-calendar-btn prev-btn'
            onClick={handlePrev}
            disabled={currentSelectedDateIndex === 0}
          >
            <Arrow />
          </button>

          <div className='events-calendar-dates'>
            {dates.map((date, index) => {
              return (
                <button
                  className='events-calendar-btn date-btn'
                  aria-checked={currentDate === date}
                  ref={(el) => (datesRef.current[index] = el)}
                  onClick={() => {
                    if (currentDate === date) {
                      setCurrentDate(null);
                      return;
                    }
                    setCurrentDate(date);
                  }}
                >
                  <span className='day'>{new Date(date).getDate()}</span>
                  <span className='month'>{new Date(date).toLocaleString('en-US', { month: 'short' })}</span>
                </button>
              );
            })}
          </div>

          <button
            className='events-calendar-btn next-btn'
            onClick={handleNext}
            disabled={currentSelectedDateIndex === dates.length - 1}
          >
            <Arrow />
          </button>
        </div>

        <div className='events'>
          {filteredEvents.map((event) => {
            return <EventItem key={event.id} event={event} />;
          })}
        </div>
      </div>

      {button && (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            marginTop: '32px',
          }}
        >
          <Link href={button.link} className='view-all-events'>
            {button.label}
            <Arrow />
          </Link>
        </div>
      )}
    </section>
  );
};

const Arrow = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.98765 8.88539L9.27982 0.426804C9.80631 -0.118912 10.596 -0.118912 11.1225 0.426804C11.649 0.972519 11.649 1.79109 11.1225 2.33681L4.01495 9.56754H18.4934C19.2831 9.56754 19.8096 10.2497 19.8096 10.9318C19.8096 11.614 19.2831 12.2961 18.4934 12.2961H4.14657L11.1225 19.5268C11.649 20.0726 11.649 20.8911 11.1225 21.4368C10.8593 21.7097 10.4644 21.8461 10.2012 21.8461C9.93793 21.8461 9.54307 21.8461 9.27982 21.5733L1.11927 13.1147C0.592785 12.569 0.32954 11.8868 0.32954 11.0683C0.32954 10.2497 0.461163 9.43111 0.98765 8.88539Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default UpcomingEvents;
