import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const SocialIconsPanel = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulWebSocialFeedPanel {
        title
        icons {
          title
          icon {
            id
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          link
        }
      }
    }
  `)
  const socialIcons = data.contentfulWebSocialFeedPanel.icons
  const handleClick = (event, item) => {
    event.stopPropagation()
    event.preventDefault()
    window.lmSMTObj.track('socialMediaClickedSMC', {
      label: `${item.title}`,
    })

    window.open(item.link, '_blank')
  }

  return (
    <nav>
      <ul className='social-icons'>
        {socialIcons.map((item) => {
          const { icon, link } = item
          return (
            <li key={icon.id}>
              <a href={'#javascript'} onClick={(event) => handleClick(event, item)}>
                <img src={icon.file.url} alt={icon.description} />
              </a>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default SocialIconsPanel
