import React, { useContext } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { LanguageContext } from 'context'
import { translateData } from 'utils'
import Button from './../common/button'
import pattern from '../../assets/images/drivers-pattern.svg'

const DriversTabsCarousel = ({ component }) => {
  const { mainTitle, description, button, vehiclesCategory } = component
  const currentLang = useContext(LanguageContext).currentLang
  const dictionary = useContext(LanguageContext).staticData
  const driverInfo = translateData(dictionary)

  const NextArrow = (props) => {
    const { onClick } = props
    return <button type='button' data-role='none' className='next-arrow' onClick={onClick}></button>
  }

  const PrevArrow = (props) => {
    const { onClick } = props
    return <button type='button' data-role='none' className='prev-arrow' onClick={onClick}></button>
  }

  const driverStatChange = (oldIndex, newIndex) => {
    const driverStatSlides = document.querySelectorAll('.driver-data-wrapper')
    const driverNamePlates = document.querySelectorAll('.driver-slide .driver-name')
    driverStatSlides[oldIndex].classList.add('hide')
    driverNamePlates[oldIndex].classList.add('hide')
    driverStatSlides[newIndex].classList.remove('hide')
    driverNamePlates[newIndex].classList.remove('hide')
  }

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    swipe: false,
    cssEase: 'linear',
    fade: false,
    centerMode: true,
    centerPadding: '250px',
    beforeChange: driverStatChange,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? 2 : 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 991,
        settings: {
          centerPadding: '200px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 550,
        settings: {
          centerPadding: '10px',
        },
      },
    ],
  }

  function rtl_slick() {
    return currentLang === 'ar-SA' ? true : false
  }

  return (
    <section className='drivers-tabs-area'>
      <img className='top-pattern' src={pattern} alt='' />
      <div className='pattern-bg'></div>
      <div className='container'>
        <div className='header-wrapper'>
          <div className='sec-title light'>
            <i className='pattern'></i>
            <h1>{mainTitle}</h1>
          </div>
          <Button btn={button} type='driverInfoSMC' className='btn-primary bg-transparent' />
        </div>
        <div className='content-wrapper'>{description && <p className='description'>{description.description}</p>}</div>
        <Tabs>
          <TabList className='tab-list list-unstyled' role='tablist'>
            {vehiclesCategory.map((vehicleCategory) => {
              const { id, vehicleName } = vehicleCategory
              return (
                <Tab className='nav-item' key={id} selectedClassName='active'>
                  <span>{vehicleName}</span>
                  <span className='rt-arrow'></span>
                </Tab>
              )
            })}
          </TabList>
          {vehiclesCategory.map((vehicleCategory) => {
            const { id, drivers } = vehicleCategory
            const sortedDrivers = drivers?.sort((a, b) => b.driverPoints - a.driverPoints).slice(0, 3)
            const driversDirection = currentLang === 'ar-SA' ? [...sortedDrivers].reverse() : sortedDrivers
            return (
              <TabPanel key={id} className='section-content' selectedClassName='active'>
                <div className='row'>
                  <div className='col-lg-4'>
                    <div>
                      {driversDirection?.map((driver, index) => {
                        const {
                          id,
                          driverName,
                          teamTitle,
                          entryNumber,
                          driverPoints,
                          car,
                          country,
                          dateOfBirth,
                          driverRank,
                        } = driver
                        return (
                          <div key={id} className={`driver-data-wrapper ${index !== 0 ? 'hide' : ''}`}>
                            <h1 className='driver-name'>
                              <span className='name'>{driverName}</span>
                              <span className='rank'>{driverRank}</span>
                            </h1>
                            {teamTitle && (
                              <div className='driver-data'>
                                <div className='data-name'>{driverInfo['driver-team']}</div>
                                <div className='data-content'>{teamTitle}</div>
                              </div>
                            )}
                            {entryNumber && (
                              <div className='driver-data'>
                                <div className='data-name'>{driverInfo['entry-number']}</div>
                                <div className='data-content'>{entryNumber}</div>
                              </div>
                            )}
                            {driverPoints && (
                              <div className='driver-data'>
                                <div className='data-name'>{driverInfo['driver-points']}</div>
                                <div className='data-content'>{driverPoints}</div>
                              </div>
                            )}
                            {car && (
                              <div className='driver-data'>
                                <div className='data-name'>{driverInfo['car']}</div>
                                <div className='data-content'>{car}</div>
                              </div>
                            )}
                            {country && (
                              <div className='driver-data'>
                                <div className='data-name'>{driverInfo['driver-country']}</div>
                                <div className='data-content'>{country}</div>
                              </div>
                            )}
                            {dateOfBirth && (
                              <div className='driver-data'>
                                <div className='data-name'>{driverInfo['driver-birth-date']}</div>
                                <div className='data-content'>{dateOfBirth}</div>
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    <Slider {...sliderSettings} className='driver-pics'>
                      {sortedDrivers?.map((item, index) => {
                        const { id, driverName, driverProfilePicture } = item
                        const pathToImage = getImage(driverProfilePicture)

                        return (
                          <div className='driver-slide' key={id}>
                            {currentLang === 'ar-SA' && (
                              <h3 className={`driver-name ${index !== driversDirection.length - 1 ? 'hide' : ''}`}>
                                <span>{driverName}</span>
                              </h3>
                            )}
                            {currentLang === 'en-US' && (
                              <h3 className={`driver-name ${index !== 0 ? 'hide' : ''}`}>{driverName}</h3>
                            )}
                            <GatsbyImage image={pathToImage} alt={driverName} className='driver-image' />
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
              </TabPanel>
            )
          })}
        </Tabs>
      </div>
    </section>
  )
}

export default DriversTabsCarousel
