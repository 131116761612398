import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useState, useContext, useRef } from 'react';
import { LanguageContext } from 'context';
import { MenuItem, HeaderTopMenu } from 'components';
import { useEffect } from 'react';
import { translateData } from 'utils';

const Navbar = ({ menuItems = [], topMenuItems, headerItems, slug }) => {
  const navWrapper = useRef(null);
  const currentLang = useContext(LanguageContext).currentLang;
  let localePath;
  if (currentLang === 'ar-SA') {
    localePath = '';
  } else {
    localePath = 'en/';
  }

  const dictionary = useContext(LanguageContext).staticData;
  const linksInfo = translateData(dictionary);

  const { logoImage, coloredLogoImage } = headerItems;

  const [show, setShow] = useState(false);
  const [subMenu, setSubMenu] = useState({});

  const showClass = classNames({
    'navbar-collapse collapse': true,
    show,
  });

  const openTogglerClass = classNames({
    'navbar-toggler': true,
    open: show,
  });

  const handleCloseMenu = () => {
    setShow(false);
  };

  const toggleSubMenu = (key) => {
    setSubMenu({
      ...subMenu,
      [key]: !(subMenu[key] || false),
    });
  };

  const menuToggler = () => {
    setShow(!show);
    setSubMenu({});
  };

  useEffect(() => {
    const navOffsetTop = navWrapper.current.offsetTop;

    const scrollingFun = () => {
      if (window.pageYOffset >= navOffsetTop) {
        navWrapper.current.classList.add('sticky-nav');
      } else {
        navWrapper.current.classList.remove('sticky-nav');
      }
    };

    window.addEventListener('scroll', scrollingFun);

    return () => {
      window.removeEventListener('scroll', scrollingFun);
    };
  }, [slug]);

  const changeToEnglish = () => {
    document.documentElement.removeAttribute('dir', 'rtl');
    document.documentElement.removeAttribute('lang', 'ar');
    document.body.classList.remove('rtl');
  };

  const changeToArabic = () => {
    document.documentElement.setAttribute('dir', 'rtl');
    document.documentElement.setAttribute('lang', 'ar');
    document.body.classList.add('rtl');
  };

  return (
    <>
      <div
        id='wrapper-navbar'
        ref={navWrapper}
        className={
          slug === 'smc-home' ||
          slug === 'events-calendar' ||
          slug?.includes('smc-news') ||
          slug === 'terms-and-conditions' ||
          slug === '404' ||
          slug === 'privacy-policy' ||
          slug === 'vendor-registration' ||
          slug === 'magazine' ||
          slug === 'ferrari-festival' ||
          slug === 'jcc-fit-register'
            ? 'home-nav'
            : ''
        }
      >
        <a className='skip-link sr-only sr-only-focusable' href='#content'>
          Skip to content
        </a>
        <nav id='main-nav' className='navbar navbar-expand-xl' aria-labelledby='main-nav-label'>
          <h2 id='main-nav-label' className='sr-only'>
            Main Navigation{' '}
          </h2>
          <div className='container'>
            <Link to={`/${localePath}`} className='navbar-brand custom-logo-link' rel='home' aria-current='page'>
              {/* <img width='100' src={logoImage.file.url} className='white-logo' alt={logoImage.description} /> */}
              <img
                width='100'
                src={coloredLogoImage?.file.url}
                className='colored-logo'
                alt={coloredLogoImage?.description}
              />
            </Link>
            <button
              className={openTogglerClass}
              type='button'
              data-toggle='collapse'
              data-target='#navbarNavDropdown'
              aria-controls='navbarNavDropdown'
              aria-expanded='true'
              aria-label='Toggle navigation'
              onClick={menuToggler}
            >
              <span className='navbar-toggler-icon'></span>
            </button>
            <div id='navbarNavDropdown' className={showClass}>
              <ul id='main-menu' className='navbar-nav ml-auto'>
                {menuItems.map((page) => {
                  return (
                    <MenuItem
                      page={page}
                      onCloseMenu={handleCloseMenu}
                      key={page.slug}
                      onToggleSubMenu={() => {
                        toggleSubMenu(page.slug);
                      }}
                      isSubMenuClose={subMenu[page.slug] || false}
                    />
                  );
                })}
                <li className='language-switcher'>
                  {currentLang === 'ar-SA' && (
                    <Link
                      to={slug ? `/en/${slug === 'smc-home' ? '' : slug}` : '/en/'}
                      onClick={changeToEnglish}
                      className='nav-link'
                    >
                      <span lang='en'>{linksInfo['link-language-name']}</span>
                    </Link>
                  )}
                  {currentLang === 'en-US' && (
                    <Link
                      to={slug ? `/${slug === 'smc-home' ? '' : slug}` : '/'}
                      onClick={changeToArabic}
                      className='nav-link'
                    >
                      <span lang='ar'>{linksInfo['link-language-name']}</span>
                    </Link>
                  )}
                </li>

                <li className='top-header-menu'>
                  <HeaderTopMenu topMenuItems={topMenuItems} slug={slug} />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
