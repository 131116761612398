import React, { useContext } from 'react';
import { LanguageContext } from 'context';
import { formatTheDate, translateData } from 'utils';
import { Link } from 'gatsby';

const EventsCalendar = ({ component }) => {
  const { mainTitle, events } = component;
  const currentLang = useContext(LanguageContext).currentLang;
  const dictionary = useContext(LanguageContext).staticData;
  const eventsInfo = translateData(dictionary);

  return (
    <section className='event-calendar-area'>
      <div className='container'>
        <h1 className='l-transp'>{mainTitle}</h1>
        <div className='event-calendar-wrapper'>
          <div>
            <div className='header-wrapper'>
              <h5 className='cell'>{eventsInfo['event-title']}</h5>
              <h5 className='cell'>{eventsInfo['date']}</h5>
              <h5 className='cell'>{eventsInfo['description']}</h5>
            </div>
            <div className='body-wrapper'>
              {events.map((event) => {
                const { id, eventName, slug, startDate, endDate, eventDescription, viewEvent, comingSoon, isFinished } =
                  event;
                const date = formatTheDate(startDate, endDate, currentLang);
                return (
                  <div key={id} className='event-wrapper'>
                    <h5 className='cell'>
                      <span className='cell-span'>{eventName}</span>
                    </h5>
                    <h5 className='cell'>
                      {date? (
                        <span className='cell-span'>{date}</span>
                      ) :(
                        <span className='cell-span'>TBA</span>
                      )}
                    </h5>
                    <h5 className='cell'>
                      <span className='cell-span'>{eventDescription?.eventDescription}</span>
                      {viewEvent && (
                        <Link
                          to={currentLang === 'ar-SA' ? `/${slug}` : `/en/${slug}`}
                          className={`btn-primary ${isFinished ? ' previous' : ''}`}
                        >
                          <span>{eventsInfo['view-event']}</span>
                          <i className='right-arrow-icon'></i>
                        </Link>
                      )}
                      {comingSoon && <a className='btn-primary coming-soon'>Coming Soon</a>}
                    </h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventsCalendar;
