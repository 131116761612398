import React, { useContext, useRef, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import { LanguageContext } from 'context';
import { ArticleCategory } from 'components';
import { translateData } from 'utils';

const LatestNews = ({ component }) => {
  const newsArticles = component.articleCategory.article;
  const sortedArticles = [...newsArticles]
    .filter((item) => item.featured === true)
    .sort((a, b) => (new Date(b.articleDate) > new Date(a.articleDate) ? 1 : -1))
    .slice(0, 6);
  const dictionary = useContext(LanguageContext).staticData;
  const currentLang = useContext(LanguageContext).currentLang;
  const newsInfo = translateData(dictionary);
  const { slug: newsCategory } = component.articleCategory;
  const sliderRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState(0);

  const rtl_slick = () => {
    return currentLang === 'ar-SA' ? true : false;
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0); // Go to the first slide explicitly
    }
  }, []);

  const responsiveSettings = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const defaultSlidesToShow = 3; // Adjust this based on your default settings

  const sliderSettings = {
    infinite: false,
    speed: 400,
    slidesToShow: defaultSlidesToShow,
    slidesToScroll: 1,
    dots: false,
    swipe: true,
    cssEase: 'linear',
    fade: false,
    draggable: true,
    centerMode: false,
    arrows: false,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? sortedArticles.length - defaultSlidesToShow : 0,
    afterChange: (current) => setCurrentSlide(current),
    responsive: responsiveSettings,
  };

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    window.lmSMTObj.track('newsPanelClickedSMC', {
      label: `All news`,
    });

    window.open(`${currentLang === 'ar-SA' ? '/smc-news' : '/en/smc-news'}`, '_self');
  };

  return (
    <section className='latest-news'>
      <div className='container'>
        <div className='header-wrapper'>
          <div className='sec-title'>
            <h1>{newsInfo['smc-news']}</h1>
          </div>

          <div className='slider-btns'>
            <button className={`slick-prev`} onClick={() => sliderRef.current.slickPrev()}>
              <Arrow />
            </button>

            <button className='slick-next' onClick={() => sliderRef.current.slickNext()}>
              <Arrow />
            </button>
          </div>
        </div>
      </div>

      <div className='slider-container'>
        <Slider {...sliderSettings} className='latest-news-slider' ref={sliderRef}>
          {sortedArticles.map((item) => {
            return <ArticleCategory newsCategory={newsCategory} article={item} key={item.id} />;
          })}
        </Slider>
      </div>

      <div className='container' style={{ marginTop: "-50px" }}>
        <a href={'javascript:void(0)'} onClick={(event) => handleClick(event)} id='news-tab' className='all-news'>
          <span>{newsInfo['news-all']}</span>
          <Arrow />
        </a>
      </div>
    </section>
  );
};

const Arrow = () => {
  return (
    <svg
      className='right-arrow'
      width='12'
      height='12'
      viewBox='0 0 20 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_461_6657)'>
        <path
          d='M18.9342 8.88539L10.6421 0.426804C10.1156 -0.118912 9.32583 -0.118912 8.79935 0.426804C8.27286 0.972519 8.27286 1.79109 8.79935 2.33681L15.9069 9.56754H1.42852C0.638792 9.56754 0.112305 10.2497 0.112305 10.9318C0.112305 11.614 0.638792 12.2961 1.42852 12.2961H15.7753L8.79935 19.5268C8.27286 20.0726 8.27286 20.8911 8.79935 21.4368C9.06259 21.7097 9.45745 21.8461 9.7207 21.8461C9.98394 21.8461 10.3788 21.8461 10.6421 21.5733L18.8026 13.1147C19.3291 12.569 19.5923 11.8868 19.5923 11.0683C19.5923 10.2497 19.4607 9.43111 18.9342 8.88539Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_461_6657'>
          <rect width='19.6117' height='21.965' fill='white' transform='translate(0.112305 0.0175171)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LatestNews;
