import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';

const HomeHero = ({ component, wrapper }) => {
  const content = documentToReactComponents(JSON.parse(component.description.raw), {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.[0] === '') return;
        return <p>{children}</p>;
      },
    },
  });

  return (
    <section
      className='hero-section relative'
      style={{
        backgroundImage: `url(${component.image.file.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='container'>
        <div className='hero-content'>
          {component.subtitle && <div className='subtitle'>{component.subtitle}</div>}
          <h1>
            <span className='relative'>{component.title}</span>
          </h1>
          <p className='description'>{content}</p>
          {/* <div>
            <a href={component.button.link} className='secondary-btn btn-lg'>
              {component.button.label}
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
