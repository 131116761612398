import React, { useContext } from 'react';
import { Button, EventItem } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { LanguageContext } from 'context';
import { formatEventDateWithTime } from 'utils';

const EventsGrid = ({ component, wrapper = '' }) => {
  const { title, events, button } = component;
  const currentLang = useContext(LanguageContext).currentLang;
  return (
    <>
      {wrapper === 'jcc-fit-events' ? (
        <section className='jcc-fit-events-grid'>
          <div className='container'>
            <h1 class='title l-transp'>{title}</h1>
            <div className='row'>
              {events.length > 0 &&
                events.map((event) => {
                  const startDate = formatEventDateWithTime(event.startDate, currentLang);
                  const endDate = formatEventDateWithTime(event.endDate, currentLang);
                  const eventImage = getImage(event.eventMainImage);
                  return (
                    <div className='col-md-6 col-lg-4 col-xl-3' key={event.id}>
                      <div className='event-card h-100'>
                        <GatsbyImage image={eventImage} alt={event.eventName} />
                        <div className='card-content'>
                          <h3 className='event-title'>{event.eventName}</h3>
                          {/* <div className='event-date'>
                          {startDate.hours!=12 && startDate.ampm!='am'? (
                            <span className='time'>
                              {currentLang === 'ar-SA' 
                                ? <span>{startDate.hours}:{startDate.minutes} {startDate.ampm} - {endDate.hours}:{endDate.minutes} {endDate.ampm}</span>
                                : <span>{startDate.hours}:{startDate.minutes} {startDate.ampm} - {endDate.hours}:{endDate.minutes} {endDate.ampm}</span>
                              }
                              </span>
                          ):(
                            <span className='time'> </span>
                          )}
                            <span className='date'>{startDate.day} {startDate.month} {startDate.year}</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      ) : (
        <section className='events-grid'>
          <div className='container'>
            <div className='row'>
              {events.length > 0 &&
                events.map((event) => {
                  return (
                    <div className='col-md-6' key={event.id}>
                      <EventItem event={event} />
                    </div>
                  );
                })}
            </div>
            <div className='btn-wrapper'>
              <Button type='eventsClickedSMC' btn={button} className='btn-primary' />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default EventsGrid;
