import React, { useState, useEffect } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Button } from 'components';
import darkGreen from '../../assets/images/about-event-dark.svg';
import lightGreen from '../../assets/images/about-event-light.svg';
import { useScreenSize } from 'utils';

const DARK_GREEN = 'Dark Green';

const AboutEvent = ({ component, wrapper }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const screenSize = useScreenSize();
  const { mainTitle, description, patternTheme, button, image } = component;
  const extension = image.file.url.substring(image.file.url.lastIndexOf('.') + 1, image.file.url.length);

  let descriptionText;
  let references;
  if (description) {
    descriptionText = JSON.parse(description.raw);
    references = description.references;
  }

  const entryMap = new Map();

  for (const entry of references) {
    entryMap.set(entry.contentful_id, entry);
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        if (children[0] === '') return;
        return <p>{'children'}</p>;
      },
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        if (children[0] === '') return;
        const entry = entryMap.get(node.data.target.sys.id);
        if (entry) {
          return (
            <a href={entry.link} target={'_blank'} title={entry.title}>
              <img src={entry.appStoreImage.file.url} className='playstore-images' />
            </a>
          );
        }

        return <p>{children}</p>;
      },
    },
  };

  let blocks;
  if (descriptionText) {
    blocks = documentToReactComponents(descriptionText, options);
  }

  useEffect(() => {
    // Calculate the height of the content in the right-hand column
    const contentWrapper = document.querySelector('.content');
    if (contentWrapper) {
      const height = contentWrapper.clientHeight;
      setContentHeight(height + 'px');
    }
  }, []);

  return (
    <Controller>
      <section className={`about-event-area ${wrapper}`} id='about-event-wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className={`image-wrapper ${component.wrapper === 'about-magazine' || component.wrapper === 'about-ferrari' ? 'about-magazine' : ''}`}
>
                <Scene
                  offset={-200}
                  classToggle='animate__fadeInLeft'
                  triggerElement='#about-event-wrapper'
                  reverse={true}
                >
                  {extension === 'jpg' || extension === 'png' || extension === 'gif' ? (
                    <img
                      className='main-image animate__animated'
                      src={image.file.url}
                      alt={image.description || ''}
                      loading='lazy'
                      style={{ height: (component.wrapper === 'about-magazine' || component.wrapper === 'about-ferrari') && screenSize.width >= 992 ? contentHeight : 'auto' }}
                    />
                    // <div>
                    //   <img
                    //     className={component.wrapper === 'about-magazine' ? 'main-image' : 'main-image shaped-image'}
                    //     src={image.file.url}
                    //     alt={image.description || ''}
                    //     loading='lazy'
                    //     style={{ height: component.wrapper === 'about-magazine' && screenSize.width >= 992 ? contentHeight : 'auto' }}
                    //   />
                    //   <svg width='0' height='0' xmlns='http://www.w3.org/2000/svg'>
                    //     <defs>
                    //       <clipPath id='svgPath2' clipPathUnits='objectBoundingBox'>
                    //         <path
                    //           transform='scale(0.00116,0.0020)'
                    //           d='M114.021 60.3391C125.948 24.3195 159.618 0 197.561 0H816.62C843.889 0 863.164 26.6866 854.592 52.5731L742.979 389.661C731.052 425.68 697.382 450 659.439 450H40.3801C13.1115 450 -6.16376 423.313 2.40755 397.427L114.021 60.3391Z'
                    //           fill='white'
                    //         />
                    //       </clipPath>
                    //     </defs>
                    //   </svg>
                    // </div>
                  ) : (
                    <div class='video-clip'>
                      <video class='main-image' loop autoPlay muted>
                        <source src={image.file.url} type='video/mp4' />
                      </video>
                      <svg width='0' height='0' xmlns='http://www.w3.org/2000/svg'>
                        <defs>
                          <clipPath id='svgPath' clipPathUnits='objectBoundingBox'>
                            <path
                              transform='scale(0.00116,0.0020)'
                              d='M114.021 60.3391C125.948 24.3195 159.618 0 197.561 0H816.62C843.889 0 863.164 26.6866 854.592 52.5731L742.979 389.661C731.052 425.68 697.382 450 659.439 450H40.3801C13.1115 450 -6.16376 423.313 2.40755 397.427L114.021 60.3391Z'
                              fill='white'
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  )}
                </Scene>

                {/* <Scene
                  offset={-200}
                  classToggle='animate__fadeInLeft'
                  triggerElement='#about-event-wrapper'
                  reverse={true}
                >
                  {patternTheme === DARK_GREEN ? (
                    <img alt='' src={darkGreen} className={`image-pattern animate__animated animate__delay-1s ${component.wrapper==='about-ferrari' ? 'd-none': ''}`} />
                  ) : (
                    <img alt='' src={lightGreen} className={`image-pattern animate__animated animate__delay-1s ${component.wrapper==='about-magazine' ? 'd-none': ''}`} />
                  )}
                </Scene> */}
              </div>
            </div>
            <div className='col-lg-6'>
              <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#about-event-wrapper' reverse={true}>
                <div className={`content-wrapper animate__animated animate__delay-1s ${component.wrapper==='about-magazine' || component.wrapper==='about-ferrari' ? 'content': ''}`}>
                  <h1 className='main-title'>{mainTitle}</h1>
                  {description && <div className={`description`}>{blocks}</div>}
                  {/* {button && <Button className='btn-primary' type='buyTicketClickedSMC' btn={button} />} */}
                </div>
              </Scene>
            </div>
          </div>
        </div>
        {/* {eventLogo && <img alt="" src={eventLogo.file.url} className="event-logo d-none d-lg-block" />} */}
      </section>
    </Controller>
  );
};

export default AboutEvent;
