import React, { useEffect, useRef, useState, useContext } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import mobPattern from '../../assets/images/hero-pattern-mob.svg';


const isBrowser = typeof window !== 'undefined';
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const TopBanner = ({ component, wrapper = '' }) => {
  const { mainTitle, shortTitle, backgroundImage, logoImage, ytVideoId, isVideoBackground } = component;
  const isBrowser = () => typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser && ytVideoId!=null  ) {
      async function delayedplayer() {
        if (typeof window.YT == 'undefined' || typeof window.YT.Player == 'undefined') {
          let tag = document.createElement('script');
          tag.src = '//www.youtube.com/iframe_api';
          let firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
          firstScriptTag.async = true;
        }

        while (typeof window.YT == 'undefined' || typeof window.YT.Player == 'undefined') {
          await sleep(1000);
        }

        function onYouTubePlayerAPIReady() {
          window.YT.ready(function () {
            const player = new window.YT.Player('ytplayer', {
              videoId: ytVideoId,
              playerVars: {
                showinfo: 0,
                controls: 0,
                enablejsapi: 1,
                rel: 0,
                autoplay: 1,
                playsinline: 1,
                loop: 1,
                playlist: ytVideoId,
                widgetid: 1,
                modestbranding: 1,
              },
              events: {
                onReady: onPlayerReady,
              },
            });
          });
        }

        function onPlayerReady(event) {
          event.target.mute();
          event.target.playVideo();
        }

        onYouTubePlayerAPIReady();
      }

      delayedplayer();
    }
  }, [ytVideoId]);

  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);
  return (
    <section className='top-banner'>
      {isVideoBackground ? (
        <div>
          {wrapper && wrapper === 'hero-video-title' &&
            <div className='content-container d-block'>
              <div className='container'>
                <div class="sec-title top-video-title">
                  {/* <i class="pattern"></i> */}
                  <h1>{mainTitle}</h1>
                </div>
              </div>
            </div>
          }
          <div className={`top-banner-bg video-player ${wrapper}`}>
            <div className='video-container2'>
              <div id='ytplayer'></div>
            </div>
            {/* <div className='d-block'>
              <img className='img-pattern' src={mobPattern} alt='pattern' />
            </div> */}
            {logoImage && <img alt='' src={logoImage.file.url} className='logo-image' />}
          </div>
        </div>
      ) : 
      wrapper === 'magazine-banner' || wrapper === 'ferrari-festival-banner' ?
      (
        <div style={{ backgroundImage: `url(${backgroundImage.gatsbyImageData.images.fallback.src})`, width: '100%', aspectRatio: '16/9', height: 'auto', backgroundRepeat: 'no-repeat', backgroundSize: '100%', position: 'relative' }}>
          {logoImage && <img alt='' src={logoImage.file.url} className={mainTitle === 'Ferrari Festival'? 'logo-image bottom-0': 'logo-image'}   />}
        </div>
      ):(
        <div style={{ position: 'relative' }}>
          <GatsbyImage image={image} />
          {logoImage && <img alt='' src={logoImage.file.url} className={mainTitle === 'Ferrari Festival'? 'logo-image bottom-0': 'logo-image'}   />}
        </div>
        // <BackgroundImage className={`top-banner-bg ${wrapper}`} Tag='div' {...bgImage}>
        //   {/* <div className={mainTitle === 'Ferrari Festival' ? 'd-none' : 'd-block' }>
        //     <img className='img-pattern' src={mobPattern} alt='pattern' />
        //   </div> */}
        //   {logoImage && <img alt='' src={logoImage.file.url} className={mainTitle === 'Ferrari Festival'? 'logo-image bottom-0': 'logo-image'}   />}
        // </BackgroundImage>
      )}
      {(shortTitle || mainTitle) &&
        <div className='content-container d-block'>
          <div className='container'>
            <h3 className='short-title'>{shortTitle}</h3>
            <h1 className='l-transp'>{mainTitle}</h1>
          </div>
        </div>
      }

    </section>
  );
};

export default TopBanner;
