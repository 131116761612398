import React, { useContext } from 'react'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { LanguageContext } from 'context'
import { formatTheDate } from 'utils'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

const EventItem = ({ event }) => {
  const { eventName, slug, startDate, endDate, eventMainImage, buyTicketsButton } = event
  const image = getImage(eventMainImage)
  const bgImage = convertToBgImage(image)
  const currentLang = useContext(LanguageContext).currentLang

  return (
    <div className='event-box'>
      <BackgroundImage className='image-wrapper' Tag='div' {...bgImage}>
        {/* <img className='top-pattern' src={topPattern} alt='' /> */}
        {/* <img className='bottom-pattern' src={bottomPattern} alt='' /> */}
      </BackgroundImage>
      <div className='content-wrapper'>
        <p className='event-date'>{formatTheDate(startDate, endDate, currentLang)}</p>
        <h2 className='event-name'>
          <Link to={currentLang === 'en-US' ? `/en/${slug}` : `/${slug}`}>{eventName}</Link>{' '}
        </h2>
        {/* {buyTicketsButton && (
          <Button
            btn={buyTicketsButton}
            type='buyTicketClickedSMC'
            eventName={eventName}
            className='btn-primary bg-transparent'
          />
        )} */}
        {/* <Link to="/" className="btn-primary bg-transparent">
                    <span>{linksInfo["link-buy-tickets"]}</span>
                    <i className="right-arrow-icon black"></i>
                </Link> */}
        {/* <i className="right-arrow-icon black"></i> */}
      </div>
    </div>
  )
}

export default EventItem
