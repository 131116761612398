import React, { useContext } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { LanguageContext } from 'context';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';

const ArticleCategory = ({ article, newsCategory }) => {
  const currentLang = useContext(LanguageContext).currentLang;
  const slugCategory = newsCategory;
  const { title, articleDate, articleImage, slug: newsSlug, shortDescription } = article;
  const image = getImage(articleImage);
  const bgImage = convertToBgImage(image);

  let dateArabic = new Date(articleDate).toLocaleDateString('ar-KA', {
    year: 'numeric',
    day: '2-digit',
    month: 'long',
  });

  const handleClick = (event, link, title) => {
    event.stopPropagation();
    event.preventDefault();

    window.lmSMTObj.track('newsInfoSMC', {
      label: `${title}`,
    });
    window.open(link, '_self');
  };

  return (
    <div className='news-article'>
      <BackgroundImage className='image-wrapper' Tag='div' {...bgImage}></BackgroundImage>
      <div className='content-wrapper'>
        <h2 className='date'>{currentLang === 'ar-SA' ? dateArabic : articleDate}</h2>

        <h1 className='title'>
          {currentLang === 'ar-SA' ? (
            <a
              // to={`/${slugCategory}/${newsSlug}`}
              href={'javascript:void(0)'}
              onClick={(e) => handleClick(e, `/${slugCategory}/${newsSlug}`, title)}
            >
              {title}
            </a>
          ) : (
            <a
              // to={`/en/${slugCategory}/${newsSlug}`}
              href={'javascript:void(0)'}
              onClick={(e) => handleClick(e, `/en/${slugCategory}/${newsSlug}`, title)}
            >
              {title}
            </a>
          )}
        </h1>

        {shortDescription && <p className="article-description">{shortDescription}</p>}
      </div>
    </div>
  );
};

export default ArticleCategory;
