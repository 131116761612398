import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import pattern2 from '../../assets/images/about-pattern2.svg';
import pattern3 from '../../assets/images/about-pattern3.svg';

const AboutCircuit = ({ component }) => {
  const { title1, title2, content1, content2, image1, image2 } = component;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children[0] === '') return;
        return <p>{children}</p>;
      },
    },
  };

  let contentTextDocument1;
  let contentTextDocument2;
  if (content1) {
    contentTextDocument1 = JSON.parse(content1.raw);
  }

  let blocks1;
  if (contentTextDocument1) {
    blocks1 = documentToReactComponents(contentTextDocument1, options);
  }

  if (content2) {
    contentTextDocument2 = JSON.parse(content2.raw);
  }

  let blocks2;
  if (contentTextDocument2) {
    blocks2 = documentToReactComponents(contentTextDocument2, options);
  }

  return (
    <Controller>
      <section className='about-circuit' id='about-wrapper'>
        <div className='top-part'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 p-static'>
                <div className='image1-wrapper'>
                  <Scene offset={-200} classToggle='animate__fadeInLeft' triggerElement='#about-wrapper' reverse={true}>
                    <img alt={image1.description} src={image1.file.url} className='image1 animate__animated' />
                  </Scene>
                  {/* <Scene offset={-200} classToggle='animate__fadeInLeft' triggerElement='#about-wrapper' reverse={true}>
                    <img alt='' src={pattern2} className='image1-pattern animate__animated animate__delay-1s' />
                  </Scene> */}
                </div>
              </div>
              <div className='col-md-6'>
                {title1 && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#about-wrapper' reverse={true}>
                    <h1 className='title1 animate__animated'>{title1}</h1>
                  </Scene>
                )}
                {blocks1 && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#about-wrapper' reverse={true}>
                    <div className='blocks1 animate__animated'>{blocks1}</div>
                  </Scene>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='bottom-part' id='bottomPart'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6'>
                {title2 && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#bottomPart' reverse={true}>
                    <h1 className='title2 animate__animated'>{title2}</h1>
                  </Scene>
                )}
                {blocks2 && (
                  <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#bottomPart' reverse={true}>
                    <div className='blocks2 animate__animated'>{blocks2}</div>
                  </Scene>
                )}
              </div>
              <div className='col-md-6'>
                <div className='image2-wrapper'>
                  <Scene offset={-200} classToggle='animate__fadeInRight' triggerElement='#bottomPart' reverse={true}>
                    <img
                      alt={image2.description}
                      src={image2.file.url}
                      className='image2 img-fluid animate__animated'
                      loading='lazy'
                    />
                  </Scene>
                  {/* <Scene offset={-200} classToggle='animate__fadeInRight' triggerElement='#bottomPart' reverse={true}>
                    <img alt='' src={pattern2} className='image2-pattern animate__animated animate__delay-1s' />
                  </Scene>
                  <Scene offset={-200} classToggle='animate__fadeInRight' triggerElement='#bottomPart' reverse={true}>
                    <img alt='' src={pattern3} className='image3-pattern animate__animated animate__delay-1s' />
                  </Scene> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Controller>
  );
};

export default AboutCircuit;
