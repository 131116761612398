import React, {useContext, useEffect, useRef} from 'react';
import { LanguageContext } from 'context';
import registerBlockPattern from '../../assets/images/register-block-pattern.svg';
import { Link } from "gatsby";
import { useScreenSize } from 'utils';

const JCCFitRegistationBlock = ({mainTitle}) => {
    const currentLang = useContext(LanguageContext).currentLang;
    const registerBlockRef = useRef(null);
    const screenSize = useScreenSize();
    const isBrowser = () => typeof window !== "undefined"
    useEffect(()=> {
      if(isBrowser()){
  
        const scrollingFun = () => {
          if (window.pageYOffset >= 600 && screenSize.width >=767) {
            registerBlockRef.current.classList.add('sticky-register-block')
            registerBlockRef.current.classList.add('sticky-register-block')
          } else {
            registerBlockRef.current.classList.remove('sticky-register-block')
          }
        }
  
        window.addEventListener('scroll', scrollingFun)
  
        return () => {
          window.removeEventListener('scroll', scrollingFun)
        }
      }
      
    }, [screenSize.width]);
    return(
        <div className="register-wrapper">
            <div className='jcc-register-block' ref={registerBlockRef}>
              <div className='container register-content'>
                <h1 className='register-title'>
                  <img className='register-block-pattern' src={registerBlockPattern} alt='pattern' />
                  <div className='text'>
                    {mainTitle}
                  </div>
                </h1>
                  <a href={currentLang === 'ar-SA' ?'https://jeddahcircuit.com/ar/register' : 'https://jeddahcircuit.com/register'} className="primary-btn btn-lg register-button">
                    <span>{currentLang === 'ar-SA'? 'للتسجيل' : 'Register'}</span>
                    <i className='right-arrow-icon'></i>
                  </a>
                {/* <Button btn={component.bannerButton} className='btn-primary register-button' /> */}
              </div>
            </div>
          </div>
    )
}

export default JCCFitRegistationBlock;