const formatEventDateWithTime = (startDate, currentLang) => {
    const startDay = new Date(startDate).getDate();
    const monthStart =
      currentLang === 'ar-SA'
        ? new Date(startDate).toLocaleString('ar-KA', { month: 'short' })
        : new Date(startDate).toLocaleString('en-US', { month: 'short' });
    const yearStart = new Date(startDate).getFullYear();

    const d = new Date(startDate); 
    const hours = d.getHours();
    const formattedHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
    const formattedHoursWithLeadingZero = formattedHours < 10 ? '0' + formattedHours : formattedHours;
    const minutes  = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    const ampm = 
      currentLang === 'ar-SA'
      ? d.getHours() < 12 ? 'am' : 'م'
      : d.getHours() < 12 ? 'am' : 'pm';
    

    const data = {
      year : yearStart,
      month : monthStart,
      day : startDay,
      hours : formattedHoursWithLeadingZero,
      minutes : minutes,
      ampm : ampm,
    }
    return data;
    // `${hours}:${minutes} ${ampm} ${startDay}  ${monthStart}  ${yearStart}`;
  };

  export default formatEventDateWithTime;